import React, { useRef, useLayoutEffect } from 'react';
import Layout from '../../../components/layout';
import LetConnect from '../../../components/common/lets-coonect';
import DispalySection from '../../../components/common/service-industry-main-display'


import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger"


import call_icon from "../../../assets/png-icon/call-quality.png"
import scalability_flexibility from "../../../assets/png-icon/scalability-flexibility.png"
import visibility from "../../../assets/png-icon/visibility.png"
import distributed_network from '../../../assets/png-icon/distributed-network.png'
import setting_icon from "../../../assets/png-icon/setting.png"
import gradient_circle_1 from "../../../assets/png-icon/gradient-circle-1.png"
import gradient_circle_2 from "../../../assets/png-icon/gradient-circle-2.png"



import background from '../../../assets/images/big-data-operator-connect/banner.jpg'
import challenges from '../../../assets/images/big-data-operator-connect/challenges.jpg'
import intro from '../../../assets/images/big-data-operator-connect/introduction.jpg'
import conclusion from '../../../assets/images/big-data-operator-connect/concusion.jpg'


import './big-data-insight.scss'

const metatag = {
    "title": "Case Study"
}

const CaseStudy = () => {

    const articleRef = useRef();

    useLayoutEffect(() => {

        const ctx = gsap.context(() => {
            // Target the two specific elements we have asigned the animate class
            ScrollTrigger.create({
                trigger: ".sticky-top",
                start: "top 150px",
                end: "+=2730",
                markers: false,
                pin: true
            })
        }, articleRef);// <- Scope!

        let item = gsap.utils.toArray(".scroll-content-item");

        item.forEach((div) => {

            let active = document.querySelector(div.getAttribute("href"));

            ScrollTrigger.create({
                trigger: div,
                start: "top center",
                end: "bottom center",
                onToggle: self => self.isActive && setActive(self, active),
                markers: false // true
            });

        })

        return () => ctx.revert();

    }, []);

    const setActive = (self, active) => {

        let item = gsap.utils.toArray(".scroll-content-item");


        item.forEach((div) => {

            let element = document.querySelector(div.getAttribute("href"));

            if (element !== null) {
                element.style.opacity = 0.5;
                element.style.paddingLeft = '0px';
                element.style.borderLeftStyle = "none";
                element.style.borderLeftWidth = "0px";
                element.style.borderLeftColor = "white";
                element.style.paddingLeft = '0px';
            }

        })

        if (active != null) {
            active.style.opacity = 1;
            active.style.paddingLeft = '20px';
            active.style.borderLeftStyle = "solid";
            active.style.borderLeftWidth = "5px";
            active.style.borderLeftColor = "#F3684A";
            active.style.paddingLeft = '20px';
        }



    }

    return (
        <Layout page="operator-connect" metatag={metatag}>
            <article className="operator-connect" >

                <DispalySection image={background} title={<div> <span className='case-study' >CASE STUDY</span> <br /> AUTOMATION OF DATA RETRIEVAL , PROCESSING AND VISUALIZATION</div>} subtitle={<>From strategy to execution, we help you achieve success</>} />

                <section className='overview-section' data-theme={'light'} >

                    <div className='section-container'>
                        <div>
                            <p className='title'>CASE STUDY</p>
                            <p className='sub-title'>Digital Transformation</p>
                        </div>
                        <div>
                            <p className='title'>INDUSTRY</p>
                            <p className='sub-title'>Media and Communication</p>
                        </div>
                        <div>
                            <p className='title'>PLATFORM</p>
                            <p className='sub-title'>Big Data</p>
                        </div>
                        <div>
                            <p className='title'>SERVICES</p>
                            <p className='sub-title'>Dashboard Creation & Alert</p>
                        </div>
                    </div>

                </section>

                <section className='introduction-section' data-theme={'light'} >

                    <div className='section-container' >

                        <div className='container-content' >
                            <p className='label' > INTRODUCTION </p>
                            <p className='title' > Call  <span > Detail Records (CDRs) </span> </p>
                            <div className='container-image' >
                                <div className='image-container' >
                                    <img className='circle-decoration' src={gradient_circle_2} alt="" />
                                    <img className='circle-decoration' src={gradient_circle_1} alt="" />
                                    <img className='display-image' src={intro} alt="" />
                                </div>
                            </div>
                            {/* <p className='description'>  We are explored fetching and processing of  Call Detail Record (CDR) and integrated with the ELK Stack (Elasticsearch, Logstash, and Kibana), to provide a user-friendly dashboard for monitoring and analyzing call data. CDRs contain information about telephone calls, including call times, durations, and call parties. Such data is crucial for telecommunications companies to optimize services, troubleshoot issues, and make data-driven decisions.</p> */}
                            <p className='description'> Our client is a telecommunications provider based in the UK. Their existing manual process of downloading Call Data Records (CDRs) from Microsoft and other sources was time-consuming. The lack of advanced data analysis and visualisation hindered the company's ability to gain meaningful insights from the vast call data. To overcome these challenges, the company embarked on a project to develop an automated call data analysis and visualization system. The system aimed to efficiently download CDRs, implement advanced analysis and visualization features, enable tenant-based data analysis, and enrich the call data with relevant information. </p>
                        </div>

                        <div className='container-image' >
                            <div className='image-container' >
                                <img className='circle-decoration' src={gradient_circle_2} alt="" />
                                <img className='circle-decoration' src={gradient_circle_1} alt="" />
                                <img className='display-image' src={intro} alt="" />
                            </div>
                        </div>

                    </div>

                </section>

                <section className='challenges-section' data-theme={'light'} >

                    <div className='section-container' >

                        <div className='container-image' >
                            <img className='circle-decoration' src={gradient_circle_2} alt="" />
                            <img className='display-image' src={challenges} alt="" />
                        </div>

                        <div className='container-content' >
                            <p className='label' >CHALLENGES</p>
                            <p className='title' > Against the Odds:<span style={{ color: '#F3684A' }}> Embracing Challenges </span> </p>
                            <p className='description'> The project team encountered several challenges during the development process</p>
                        </div>

                        <div className='card-container' >

                            <div>
                            <div className='card' >
                                <img className='card-icon' src={distributed_network}  alt="" />
                                <p className='title' >Data Complexity:</p>
                                Analyzing large volumes of call data from
various sources proved difficult due to the complexity of the data.
                            </div>
                            <div className='card' >
                                <img className='card-icon' src={distributed_network}  alt="" />
                                <p className='title' >Inefficient Analysis:</p>
                                The lack of advanced data analysis tools and visualizations
made it challenging to derive meaningful insights from the raw call data.
                            </div>
                            </div>

                            <div>
                            <div className='card' >
                                <img className='card-icon' src={call_icon}  alt="" />
                                <p className='title' >Limited Personalization:</p>
                                Absence of tenant-based analysis limited the
company's ability to identify specific call patterns and preferences for individual
customers.
                            </div>
                            <div className='card' >
                                <img className='card-icon' src={visibility}  alt=""  />
                                <p className='title' >Data Enrichment Needs:</p>
                                The company recognized the importance of enhancing
call data with additional information from customer profiles and other databases
to gain deeper insights.
                            </div>
                            </div>
                            
                            
                           

                        </div>

                    </div>

                </section>


                <section className='outcome-section' data-theme={'light'} links={''} >

                    <div className='section-container'>

                        <div className='container-head' >
                            <p className='title' >SOLUTION</p>
                            <p className='subtitle' > Automated call
data <span > Analysis & Visualization </span> </p> 
                            <br /> 
                            <p className='subtitle' > The project team developed an automated call
data  system that encompassed the following solutions:</p>
                        </div>

                        <div className='container-content'  >

                            <div className='content-item' >
                                <img className='item-icon' src={setting_icon}  alt="" />
                                <p className='title' >Automated Data Retrieval</p>
                                The system integrated Microsoft APIs and
automated the data retrieval process, enabling real-time and historical CDR
downloads.
                            </div>

                            <div className='content-item' >
                                <img className='item-icon' src={call_icon}  alt="" />
                                <p className='title' >Data Processing and Cleaning</p>
                                Robust data processing algorithms were
implemented to handle the complexity and volume of call data while ensuring
data accuracy through efficient cleaning and transformation.
                            </div>

                            <div className='content-item' >
                                <img className='item-icon' src={visibility}  alt="" />
                                <p className='title' >Advanced Visualization</p>
                                The system provided interactive visualizations such as
charts, graphs, and dashboards, enabling users to explore call data insights with
ease.
                            </div>

                            <div className='content-item' >
                                <img className='item-icon' src={scalability_flexibility}  alt="" />
                                <p className='title' >Tenant-Based Analysis</p>
                                Tenant segmentation was incorporated, allowing the
company to analyze call patterns specific to individual customers, facilitating
personalized service offerings.
                            </div>


                        </div>

                    </div>

                </section>

                <section className='conclusion-section' data-theme={'light'} >

                    <div className='section-container' >

                        <div className='container-image' >
                            <div className='image-container' >
                                <img className='circle-decoration' src={gradient_circle_2} alt="" />
                                <img className='circle-decoration' src={gradient_circle_1} alt="" />
                                <img className='display-image' src={conclusion} alt="" />
                            </div>
                        </div>

                        <div className='container-content'>

                            <p className='label' >CONCLUSION</p>

                            <p className='title' >  Putting it All Together: Case Study Conclusion</p>

                                <div className='container-image' >
                                    <div className='image-container' >
                                        <img className='circle-decoration' src={gradient_circle_1} alt="" />
                                        <img className='circle-decoration' src={gradient_circle_1} alt="" />
                                        <img className='display-image' src={conclusion} alt="" />
                                    </div>
                                </div>

                                <p className='description' > 
                                        The automated call data analysis and visualization system
                                    proved to be a success. It enabled the telecommunication company to gain deeper
                                    insights into call patterns, make data-driven decisions, and optimize customer service.
                                    By overcoming the challenges of manual data retrieval, data complexity, and limited
                                    personalization, the system significantly improved call data analysis efficiency. The
                                    advanced visualization capabilities empowered stakeholders to explore call data trends
                                    and derive valuable insights. The system's tenant-based analysis feature facilitated
                                    personalized service offerings for improved customer satisfaction. Overall, the
                                    implementation of the automated system enhanced call data analysis, leading to better
                                    business outcomes and improved decision-making.
                                </p>
                            
                        </div>

                    </div>

                </section>

                <LetConnect to="/contact-us" darkicon title={<>Looking to leverage the power of data for your organisation? Please get in touch</>} buttonlabel="LET'S CONNECT" />

            </article>
        </Layout>
    )
}

export default CaseStudy;