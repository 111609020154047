import React, { useState } from 'react';
import { Link } from 'react-router-dom'

import let_connect_arrow from '../../assets/svg-icon/let-connect-arrow.svg'
import right_arrow from "../../assets/svg-icon/button-right-arrow.svg"
import white_right_arrow from "../../assets/svg-icon/button-white-right-arrow.svg"

String.prototype.rgbOpacity = function(){

    var aRgbHex = this.substring(1).match(/.{1,2}/g);
   
    var aRgb = [
        parseInt(aRgbHex[0], 16),
        parseInt(aRgbHex[1], 16),
        parseInt(aRgbHex[2], 16),
        0.5
    ];

    return aRgb;
}

function buttonBorderColor(labelcolor){

    switch (labelcolor) {
        case "white":
            return "rgb(256,256,256,0.5)"
        case "black":
            return "rgb(0,0,0,0.5)"
        default:
            if(labelcolor==undefined){
                return "rgb(256,256,256,0.5)"
            }else{
                return "rgb(0,0,0,0.5)"
            }
    }
}

export const FormSubmit = (props) => {

    const [hover,setHover]=useState(false);

    return (
        <button type='submit' style={{backgroundColor:'transparent',borderStyle:'none'}}>
         <div className={`botton-container ${props.small?'botton-container-small':''}`} onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)} style={{ borderColor: hover?buttonBorderColor(props?.labelcolor ):"transparent",cursor:'pointer'}} >
            <div className="button" title="" target={props?.target} rel="noopener noreferrer" >
                <div className={`circle`} />
                <div className='button-label' style={{color:props?.labelcolor||"white" }} >{props?.label||"Continue Reading"}</div>
                <div style={{ filter: props?.darkicon && 'brightness(10%) contrast(90%)' }} alt="" className={`button-icon right-arrow`} />
            </div>
         </div>
        </button>
 
    );
}

export const Button = (props) => {

    const [hover,setHover]=useState(false);

    return (
        <div className={`botton-container ${props.small?'botton-container-small':''}`} onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)} style={{ borderColor: hover?buttonBorderColor(props?.labelcolor ):"transparent",cursor:'pointer'}} >
            <Link to={props?.to} onClick={props?.onClick} className="button" title="" target={props?.target} rel="noopener noreferrer" >
                <div className={`circle`} />
                <div className='button-label' style={{color:props?.labelcolor||"white" }} >{props?.label||"let's get started"}</div>
                {/* <img src={ hover ? white_right_arrow : right_arrow } style={{ zIndex:20, transform:'translateX(10px)',paddingLeft:'20px',filter: props?.darkicon && 'brightness(10%) contrast(90%)' }}/> */}
                <div style={{ filter: props?.darkicon && 'brightness(10%) contrast(90%)' }} alt="" className={`button-icon right-arrow`} />
            </Link>
        </div>
    );
}

export const ButtonConnect =(props)=>{

    const [hover,setHover]=useState(false)

    return(
    <div className={`botton-container ${props.small?'botton-container-small':''}`} onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)} style={{ borderColor: hover?buttonBorderColor(props?.labelcolor ):"transparent",cursor:'pointer'}} >
        <Link to={props?.to} className="button" >
            <div className="circle" />
            <div className='button-label' style={{color:props?.labelcolor||"white" }} >{props?.label||"Let's Connect"}</div>
            <img src={let_connect_arrow} style={{ zIndex:20, transform:'translateX(10px)',paddingLeft:'20px',filter: props?.darkicon && 'brightness(10%) contrast(90%)' }}/>
        </Link>
    </div>
    )

}

export const ButtonLink =(props)=>{

    return(
        <Button {...props} />
    )

}

export const ButtonBlogBack =(props)=>{

    return(
        <div style={{transform:'scale(0.8',marginTop:'-28px'}} >
            <Button {...props} />
        </div>
        
    )

}

export const ButtonLetConnect = (props) => {

    return(
        <div className='button-let-connect-div'>
            <Link to={props?.to} >
                <div className='button-let-connect-outer' style={{ borderColor: buttonBorderColor(props?.labelcolor) }}>
                    <a className="more-button">
                            <span style={{color:props?.labelcolor }}>{props.label ? props.label : "LET'S CONNECT"}</span>
                            <img src={let_connect_arrow} style={{ filter: props?.labelcolor && 'brightness(10%) contrast(90%)' }} alt="" className="star-icon"/>
                    </a>
                </div>
            </Link>
        </div>
    )
}

// export const ButtonCircle = (props) => {
//  {/* <div className="pulsating-circle button-icon"></div> */}
//     return (
//         <Link to={props?.to} >
//             <div className='button-outer' style={{ borderColor: buttonBorderColor(props?.labelcolor)}}>
//                 <div className="button-circle">
//                     <span className="button-label" style={{ color: props?.labelcolor }}>{props.label ? props.label : "MORE"}</span>
//                     <div className='button-icon right-arrow'/>
//                 </div>
//             </div>
//         </Link>
//     )

// }

export const ButtonMinor = (props) => {

    return (
        <Link to={props?.to} >
            <div className='button-minor-outer' style={{ borderColor: buttonBorderColor(props?.labelcolor) }}>
                <div className="button-minor" >
                    <span className="button-label" style={{ color: props?.labelcolor }}>{props.label ? props.label : "EXPOLRE"}</span>
                </div>
             <div className='button-icon minor-arrow'></div>   
            </div>
        </Link>
    )

}

export default Button;