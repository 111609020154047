import {ButtonLink} from '../../components/ui/button'
import React from "react";

const MainBanner = () => {
    return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="content">
            <h1 className="title">
            Accelerate Your Digital Future with Us
            </h1>
            <h5 className="date" style={{maxWidth:'65%'}}>


            Unlock potential, drive innovation, and transform your business for the digital age. From data insights to intelligent automation, we turn challenges into growth opportunities.
            </h5>
          </div>
          <ButtonLink to="/contact-us" />
        </div>
      </div>
    </div>
     );
}
 
export default MainBanner;