import React, { useEffect } from 'react'

import { useLocation  } from 'react-router-dom'
import Layout from '../../components/layout'

import LetConnect from '../../components/common/lets-coonect'
import DispalySection from '../../components/common/service-industry-main-display'

import gradient_circle_1 from "../../assets/png-icon/gradient-circle-1.png"
import gradient_circle_2 from "../../assets/png-icon/gradient-circle-2.png"

import service_1 from "../../assets/images/services/it-consulting.png"
import service_2 from "../../assets/images/services/managed-it.png"
import service_3 from "../../assets/images/services/cloud-service.png"
import service_4 from "../../assets/images/services/maintanace-service.png"
import service_5 from "../../assets/images/services/resource-augmentation.png"

import background from "../../assets/background-images/background_09.png"

const metatag = {
    "title": "Our Services"
}

const OurServices = () => {

    const location = useLocation();

    useEffect(()=>{

        var targetElm = document.getElementById('our-services');

        // switch(location.hash){

        //     case "#IT%20Consulting%20Service":
        //         targetElm = document.getElementById('IT Consulting Service');
        //         break;
        //     case "#Managed%20IT%20Service":
        //         targetElm = document.getElementById('Managed IT Service');
        //         break;
        //     case "#Cloud%20Service":
        //         targetElm = document.getElementById('Cloud Service');
        //         break;
        //     case "#Annual%20Maintenance%20Service":
        //         targetElm = document.getElementById('Annual Maintenance Service');
        //         break;
        //     case "#Resource%20Augmentation":
        //         targetElm = document.getElementById('Resource Augmentation');
        //         break;
        //     default:
        //         targetElm = document.getElementById('root'); 
        //         break;
        
        //     }

        targetElm.scrollIntoView()
     
    },[location.hash])

    return (
        <Layout page="our-services" metatag={metatag}>

            <article className='our-services' id="our-services" >

                <DispalySection image={background} title={<>Our Services: <br /> Providing Solutions for Your Needs</>} subtitle={<>From strategy to execution, we help you achieve success</>} />

                <section className='first-section' id="IT-Consulting-Service" data-theme={'light'} >

                    <div className='section-container' >

                        <div className='container-content' >
                            <div className='wrapped-content'>
                            {/* Empowering Your Business with Trusted Digital Transformation Services.  */}
                                <p className='label' >Digital Transformation Services</p>
                                <p className='title'  ><span className='bold'> Empowering </span> <span className='color' >Your Business </span> <span className='bold'>with Trusted Digital</span> <span className='bold'>  Transformation Services. </span>
                                 
                                  </p>
                                  


                                <p className='description' >             
Our team partners with you to create and implement a strategic digital transformation roadmap that aligns with your business goals, processes, and current system capabilities.
 
                                </p>

                                <div className='bullet-point-container'>
                                    <div className='bullet-item' >
                                        <div className='icon-container'>
                                            <img className='buttet-icon' src='assets/images/yes-tick-icon.png' alt="" />
                                        </div>
                                        <div className='bullet-text' >Develop digital strategies closely connected to business objectives.</div>
                                    </div>
                                    <div className='bullet-item' >
                                        <div className='icon-container'>
                                            <img className='buttet-icon' src='assets/images/yes-tick-icon.png' alt="" />
                                        </div>
                                        <div className='bullet-text' >Optimize digital solutions to enhance and streamline existing processes.</div>
                                    </div>
                                    <div className='bullet-item' >
                                        <div className='icon-container'>
                                            <img className='buttet-icon' src='assets/images/yes-tick-icon.png' alt="" />
                                        </div>
                                        <div className='bullet-text' >Design scalable solutions that evolve with your business needs.</div>
                                    </div>
                                </div>

                            </div>
 
                            {/* <div className="mobile-card-container">
                                <img className='background-image' src={background_image_3} />
                                <div className='inner-card' >
                                    <img className='card-icon' src={quote} />
                                    <p className='title' >API Gateway</p>
                                    <p className='description'>API Gateway is primarily responsible for managing traffic between clients and backend services</p>
                                </div>
                            </div> */}

                        </div>

                        <div className='container-image' >
                            <div className='image-container' >

                                <img className='circle-decoration' src={gradient_circle_2} alt="" />
                                <img className='circle-decoration' src={gradient_circle_1} alt="" />
                                <img className='display-image' src={service_1} alt="" />

                                {/* <div className='inner-card' >
                                    <img className='card-icon' src={quote}  />
                                    <p className='description'>Freston Analytics retains its edge in the fast-evolving  has technology sector</p>
                                    <p className='title' >Adila Sharin, CEO of abcd company</p>
                                </div> */}

                            </div>
                        </div>

                    </div>

                </section>

                <section className='second-section' id="Managed IT Service" data-theme={'light'} >

                    <div className='section-container' >

                        <div className='container-image' >
                            <div className='image-container' >
                                <img className='circle-decoration' src={gradient_circle_2} alt="" />
                                <img className='circle-decoration' src={gradient_circle_1} alt="" />
                                <img className='display-image' src={service_2} alt="" />
                                {/* <div className='inner-card' >
                                    <img className='card-icon' src={quote}  />
                                    <p className='description'>Freston Analytics retains its edge in the fast-evolving  has technology sector </p>
                                    <p className='title' >Adila Sharin, CEO of abcd company</p>
                                </div> */}
                            </div>
                        </div>

                        <div className='container-content' >

                            <div className='wrapped-content'>

                                <p className='label' >Managed IT Service</p>
                                <p className='title'  > Experience Unmatched IT Support with <span className='bold'> Our Comprehensive </span>  <span className='color'> Managed IT Services </span>  </p>

                                <p className='description' >Depending on your budget and requirement, we can manage your IT infrastructure by being a part of your in-house IT team.
                                </p>

                                <div className='bullet-point-container'>
                                    <div className='bullet-item' >
                                        <div className='icon-container'>
                                            <img className='buttet-icon' src='assets/images/yes-tick-icon.png' alt="" />
                                        </div>
                                        <div className='bullet-text' >Tailor services to match specific requirements.</div>
                                    </div>
                                    <div className='bullet-item' >
                                        <div className='icon-container'>
                                            <img className='buttet-icon' src='assets/images/yes-tick-icon.png' alt="" />
                                        </div>
                                        <div className='bullet-text' >Act as an extension of your internal IT team</div>
                                    </div>
                                    <div className='bullet-item' >
                                        <div className='icon-container'>
                                            <img className='buttet-icon' src='assets/images/yes-tick-icon.png' alt="" />
                                        </div>
                                        <div className='bullet-text' >Adapt service levels based on budget and needs.</div>
                                    </div>
                                </div>

                            </div>


                            {/* <div className="mobile-card-container">
                                <img className='background-image' src={background_image_3} />
                                <div className='inner-card' >
                                    <img className='card-icon' src={quote} />
                                    <p className='title' >API Gateway</p>
                                    <p className='description'>API Gateway is primarily responsible for managing traffic between clients and backend services</p>
                                </div>
                            </div> */}

                        </div>

                    </div>

                </section>

                <section className='first-section' id="Cloud Service" href="" data-theme={'light'} >

                    <div className='section-container' >

                        <div className='container-content' >
                            <div className='wrapped-content'>

                                <p className='label' >Cloud Service</p>
                                <p className='title'  >Secure, scalable, and reliable: <span className='bold'> Discover the power of our </span>   <span className='color' > cloud solutions </span>  </p>

                                <p className='description' > Customised Service Level Agreements to help you eliminate the capital expenditure involved in buying hardware/software and running on-site data centres and on-premises infrastructure.
                                </p>

                                <div className='bullet-point-container'>
                                    <div className='bullet-item' >
                                        <div className='icon-container'>
                                            <img className='buttet-icon' src='assets/images/yes-tick-icon.png' alt="" />
                                        </div>
                                        <div className='bullet-text' > Quick access to virtual resources and services.</div>
                                    </div>
                                    <div className='bullet-item' >
                                        <div className='icon-container'>
                                            <img className='buttet-icon' src='assets/images/yes-tick-icon.png' alt="" />
                                        </div>
                                        <div className='bullet-text' > Access data and services from anywhere.</div>
                                    </div>
                                    <div className='bullet-item' >
                                        <div className='icon-container'>
                                            <img className='buttet-icon' src='assets/images/yes-tick-icon.png' alt="" />
                                        </div>
                                        <div className='bullet-text' > Avoid upfront hardware/software expenses.</div>
                                    </div>
                                </div>

                            </div>

                            {/* <div className="mobile-card-container">
                                <img className='background-image' src={background_image_3} />
                                <div className='inner-card' >
                                    <img className='card-icon' src={quote} />
                                    <p className='title' >API Gateway</p>
                                    <p className='description'>API Gateway is primarily responsible for managing traffic between clients and backend services</p>
                                </div>
                            </div> */}

                        </div>

                        <div className='container-image' >
                            <div className='image-container' >
                                <img className='circle-decoration' src={gradient_circle_2} alt="" />
                                <img className='circle-decoration' src={gradient_circle_1} alt="" />
                                <img className='display-image' src={service_3} alt="" />

                                {/* <div className='inner-card' >
                                    <img className='card-icon' src={quote}  />
                                    <p className='description'>Freston Analytics retains its edge in the fast-evolving  has technology sector </p>
                                    <p className='title' >Adila Sharin, CEO of abcd company</p>
                                </div> */}

                            </div>
                        </div>

                    </div>

                </section>

                <section className='second-section' id="Annual Maintenance Service" data-theme={'light'} >

                    <div className='section-container' >

                        <div className='container-image' >
                            <div className='image-container' >
                                <img className='circle-decoration' src={gradient_circle_2} alt="" />
                                <img className='circle-decoration' src={gradient_circle_1} alt="" />
                                <img className='display-image' src={service_4} alt="" />

                                {/* 
                                <div className='inner-card' >
                                    <img className='card-icon' src={quote}  />
                                    <p className='description'>Freston Analytics retains its edge in the fast-evolving  has technology sector </p>
                                    <p className='title' >Adila Sharin, CEO of abcd company</p>
                                </div> 
                                */}

                            </div>
                        </div>

                        <div className='container-content' >

                            <div className='wrapped-content'>

                                <p className='label' >Annual Maintenance Service</p>
                                <p className='title' > Prevent Costly Downtime: <span className='bold' > Trust Our Expert Team for </span> <span className='color'> Annual  Maintenance </span> </p>
                                <p className='description'  >Ensuring excellent user experience by evaluating and aligning your digital environment with industry best practices.</p>
                                
                               
                                <div className='bullet-point-container'>
                                    <div className='bullet-item' >
                                        <div className='icon-container'>
                                            <img className='buttet-icon' src='assets/images/yes-tick-icon.png' alt="" />
                                        </div>
                                        <div className='bullet-text' >Match practices with the latest industry standards</div>
                                    </div>
                                    <div className='bullet-item' >
                                        <div className='icon-container'>
                                            <img className='buttet-icon' src='assets/images/yes-tick-icon.png' alt="" />
                                        </div>
                                        <div className='bullet-text' >Regularly assess the digital environment's performance</div>
                                    </div>
                                    <div className='bullet-item' >
                                        <div className='icon-container'>
                                            <img className='buttet-icon' src='assets/images/yes-tick-icon.png' alt="" />
                                        </div>
                                        <div className='bullet-text' >Optimize digital resources for diverse user needs</div>
                                    </div>
                                </div>

                            </div>

                            {/* <div className="mobile-card-container">
                                <img className='background-image' src={background_image_3} />
                                <div className='inner-card' >
                                    <img className='card-icon' src={quote} />
                                    <p className='description'>Freston Analytics retains its edge in the fast-evolving  has technology sector </p>
                                    <p className='title' >Adila Sharin, CEO of abcd company</p>
                                </div>
                            </div> */}

                        </div>

                    </div>

                </section>

                <section className='first-section' id="Resource Augmentation"  data-theme={'light'} >

                    <div className='section-container' >

                        <div className='container-content' >
                            <div className='wrapped-content'>
                                <p className='label' >RESOURCE AUGMENTATION</p>
                                <p className='title'  > Experience Seamless Resource Augmentation with  <span className='bold'> Our Experienced </span>  <span className='color' > Team of Experts  </span>  </p>


                                <p className='description' > Support your technology resource requirement with a flexible, cost-effective outsourcing strategy - providing you with expert talent who can work in-house with your team on a contracting basis or remotely, as required.
                                </p>

                                <div className='bullet-point-container'>
                                    <div className='bullet-item' >
                                        <div className='icon-container'>
                                            <img className='buttet-icon' src='assets/images/yes-tick-icon.png' alt="" />
                                        </div>
                                        <div className='bullet-text' > Scale up or down without long-term commitments </div>
                                    </div>
                                    <div className='bullet-item' >
                                        <div className='icon-container'>
                                            <img className='buttet-icon' src='assets/images/yes-tick-icon.png' alt="" />
                                        </div>
                                        <div className='bullet-text' > Specialists optimize task execution </div>
                                    </div>
                                    <div className='bullet-item' >
                                        <div className='icon-container'>
                                            <img className='buttet-icon' src='assets/images/yes-tick-icon.png' alt="" />
                                        </div>
                                        <div className='bullet-text' > Access specialized talent on-demand </div>
                                    </div>
                                </div>
                            </div>
                            
                            {/* <div className="mobile-card-container">
                                <img className='background-image' src={background_image_3} />
                                <div className='inner-card' >
                                    <img className='card-icon' src={quote} />
                                    <p className='title' >API Gateway</p>
                                    <p className='description'>API Gateway is primarily responsible for managing traffic between clients and backend services</p>
                                </div>
                            </div> */}

                        </div>

                        <div className='container-image' >
                            <div className='image-container' >
                                <img className='circle-decoration' src={gradient_circle_2} alt="" />
                                <img className='circle-decoration' src={gradient_circle_1} alt="" />
                                <img className='display-image' src={service_5} alt="" />

                                {/* <div className='inner-card' >
                                    <img className='card-icon' src={quote}  />
                                    <p className='description'>Freston Analytics retains its edge in the fast-evolving  has technology sector</p>
                                    <p className='title' >Adila Sharin, CEO of abcd company</p>
                                </div> */}

                            </div>
                        </div>

                    </div>

                </section>

                <LetConnect to="/contact-us" id="Resource Augmentation" darkicon title="Okay enough about us, Lets talk about you" buttonlabel="LET'S CONNECT" />

            </article>

        </Layout>
    )
}

export default OurServices;