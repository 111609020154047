import { ButtonLink } from '../../components/ui/button';import React from "react";

const AboutFreston = () => {
    
    return (
        <>

            <div className="whoAreWe-section" data-theme="light">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">

                            <h2 className="section-title">
                                <b> We are a software solutions company,</b>
                            </h2>
                            <p className="section-text">
                                We take technology forward - helping organisations innovate, scale and transform with bespoke digital products and services.
                            </p>

                            {/* <h2 className="section-title">
                                <b>We are Freston Analytics,</b> a software technology company
                            </h2>
                            <p className="section-text">
                                We take technology forward - helping organisations innovate, scale
                                and transform with bespoke digital products and services.
                            </p> */}

                            <div style={{display:'flex',width:'fit-content',marginLeft:'-18px'}}>
                                <ButtonLink small to="/who-we-are" labelcolor="#4A4949" label="GET TO KNOW US"/>
                            </div>
                            
                        </div>
                        <div className="col-md-6 large-display">
                            <img
                                className="whoAreWe-image"
                                src="assets/images/who-are-we.png"
                                alt=""
                                title=""
                            />
                        </div>
                    </div>
                    <div className="row align-items-end large-display">
                        <div className="col-md-5">
                            <ul className="iso-icons">
                                <li>
                                    <img src="assets/images/iso-01.png" alt="" title="" />
                                </li>
                                <li>
                                    <img src="assets/images/iso-02.png" alt="" title="" />
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-7">
                            <ul className="more-links">
                                <li>
                                    <ButtonLink small to="/life-at-freston" labelcolor="#4A4949" label="LIFE @ FRESTON"/>
                                </li>
                                <li>
                                    <ButtonLink small to="/careers" labelcolor="#4A4949" label="CAREERS"/> 
                                </li>
                                <li>
                                    <ButtonLink small to="/blogs" labelcolor="#4A4949" label="BLOGS"/>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            <div className="team-count" data-theme="light">
                <div className="container">
                    <ul className="count-list">
                        <li>
                            <b className="value">100+</b>
                            <span className="label">Team Members &amp; Counting</span>
                        </li>
                        <li>
                            <b className="value">04</b>
                            <span className="label">Offices Packed with creativity</span>
                        </li>
                        <li>
                            <b className="value">10+</b>
                            <span className="label">Live Products in Market</span>
                        </li>
                    </ul>
                </div>
            </div>

            {/* Our Clients */}
            
            {/* <div className="our-clients"  data-theme="light" >

                <div className="container">

                    <div className="row">
                        <div className="col-md-6">
                            <h5 className="head">Our Happy Clients</h5>
                            <p className="section-text">
                                At our core, we're a team of enthusiastic professionals who take
                                pride in delivering exceptional services to our amazing clients.
                            </p>
                        </div>
                    </div>

                  

                </div>

            </div> */}
        </>
    );
}

export default AboutFreston;