import  React,{ useState } from "react";
import { Link } from "react-router-dom";

import { ButtonLink } from '../../components/ui/button'

import product_1 from "../../assets/images/products/Nexus 360 banner.png"
import product_2 from "../../assets/images/products/PM---Banner.jpg"
import product_3 from "../../assets/images/products/Safer-Banner.png"



const products=[
  {
  image: product_1,
  label:`Nexus360`,
  description:`Easily connect popular business and CRM apps to your contact centre, business phone, video meetings and team chat. Increase the productivity of your teams without complexity or cost.`,
  link:`/our-product-kaller-cloud`

  },
  {
    image: product_2,
    label:`Process Manager`,
    description:`Complex projects or day-to-day tasks, Process Manager simplifies and enhances task execution, making it an essential tool for modern workflow management.`,
    link:`/our-product-process-manager`
  },
  // {
  //   image: product_3,
  //   label:`Safer Circle`,
  //   description:`The Safer Circle application act as a citizen guard. leveraging the power of community engagement and advanced technology.`,
  //   link:`/our-product-safer-circle`
  // },
]

const OurProduct = () => {

  const [active,setActive] = useState(0);

  
    return ( 
      <div className="container" >

        <div className="row">

          <div className="explore">Explore</div>

          <div className="button-container">
              <div className="sub-title">Best of Our Products</div>
              <div > <ButtonLink  to="/our-products" labelcolor="black" label="More Products" /> </div>
          </div>
         
          <div className="col-md-12">

            <ul className="product-list">
              {
                products.map((item,index)=>{

                  return(
                      <li  key={index} className={index===active?"active":""} onMouseOver={()=>{setActive(index)}} onMouseLeave={()=>{setActive(0)}}>
                        <Link to={item.link}>
                        <div className="image">
                          <img src={item.image} alt="" />
                        </div>
                        <div className="content">
                          <h5 className="name">{item.label}</h5>
                          <p className="text">{item.description}</p>
                        </div>
                        </Link>
                      </li>
                  )

                })
              }
            </ul>
            
          </div>

        </div>

      </div>
     );

}
 
export default OurProduct;