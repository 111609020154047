import React from 'react'
// import logo from '../../assets/logo/footer-logo-color.png'
import whiteLogo from '../../assets/logo/header-logo-white.png'

import { Link } from 'react-router-dom'

import social_01 from "../../assets/images/social-01.svg"
import social_gallery_01 from "../../assets/images/social-01-gallery.svg"
import social_02 from "../../assets/images/social-02.svg"
import social_gallery_02 from "../../assets/images/social-02-gallery.svg"
import social_03 from "../../assets/images/social-03.svg"
import social_gallery_03 from "../../assets/images/social-03-gallery.svg"
import social_04 from "../../assets/images/social-04.svg"
import social_gallery_04 from "../../assets/images/social-04-gallery.svg"

const date = new Date();


const Footer= ()=>{

  // useEffect(()=>{
  //   let selectFooter = document.getElementById("footer");
  //   let selectArticle = document.getElementsByTagName("article");
  //   let offsetHeight = selectFooter.getBoundingClientRect().height
  //   selectArticle[0].style.marginBottom = offsetHeight + "px";
  // },[])

  // const MainLinks =[
  //   {name:"Who are we",link:'who-we-are'},
  //   {name:"Our Products",link:'our-products'},
  //   {name:"Our Services",link:'our-services'},
  //   {name:"Our Industries",link:'our-industries'},
  //   {name:"Our Solutions",link:'our-solutuions'},
  //   {name:"ProSEED",link:'freston-proceed'},
  // ]

  // const MinorLinks =[
  //   {name:"Career",link:'careers'},
  //   {name:"Blogs",link:'blogs'},
  //   {name:"Life @ Freston",link:'life-at-freston'},
  // ]

  const footerLink=[
    // [
    //   {label:'Our Solutions',link:''},
    //   // {label:'Big Data Analytics',link:'/home/big-data'},
    //   {label:'AI Ops',link:'/ai-operation-solution'},
    //   {label:'Unified Communications',link:'/unified-communication-platform'},
    //   {label:'5G 3GPP Specification',link:'/5g-3gpp-specification'},
    // ],
    [
      {label:'Our Service',link:'#'},
      {label:'Digital Transformation Services',link:'/our-services#IT-Consulting-Service'},
      {label:'Managed IT Service',link:'/our-services#Managed-IT-Service'},
      {label:'Cloud Service',link:'/our-services#Cloud-Service'},
      {label:'Annual Maintenance Service',link:'/our-services#Annual-Maintenance-Service'},
      {label:'Resource Augmentation',link:'/our-services#Resource-Augmentation'},
    ],
    [
      {label:'Join Us',link:'#'},
      {label:'Career',link:'/careers'},
      {label:'ProSEED',link:'/freston-proceed'},
      {label:"Life @ Freston",link:'/life-at-freston'},
      {label:"Contact Us",link:'/contact-us'},
    ]
  ]

// return(
//   <footer id="footer" className="footer">
//     <div className="container">
//       <div className="row">
//         <div className="col-md-5">
//           <img
//             src={logo}
//             alt=""
//             className="footer-logo"
//           />
//           <h2 className="footer-title">
//             We Make <br />
//             Digital <br />
//             Transformation
//           </h2>
//         </div>
//         <div className="col-md-7">
//           {
//             footerLink.map((item,index)=>{
//               return(
//                 <ul className="footer-list" key={index}>
                  
//                   {
//                     item.map((link,index)=>{
//                       if(index==0){
//                         return(
//                           <h5 className="head">{link.label}</h5>
//                         )
//                       }else{
//                         return(
//                           <li key={index}>
//                             <a href={`${link.link}`}>{link.label}</a>
//                           </li>
//                           )
//                       }
//                     })
//                   }

//               </ul>
//               )
//             })
//           }
//         </div>
//       </div>
//       <div className="row">
//         <div className="col-md-12">
          // <ul className="footer-social" >
          //   <li>
          //     <a href="https://www.instagram.com/frestonanalytics" target="_blank" style={{color:"red"}}>
          //       <img className='default' src="assets/images/social-01.svg" alt="" />
          //       <img className='active' src="assets/images/social-01-gallery.svg" alt="" />
          //     </a>
          //   </li>
          //   <li>
          //     <a href="https://twitter.com/frestonanalytic" target="_blank">
          //       <img className='default' src="assets/images/social-02.svg" alt="" />
          //       <img className='active' src="assets/images/social-02-gallery.svg" alt="" />
          //     </a>
          //   </li>
          //   <li>
          //     <a  href="https://www.facebook.com/frestonanalytics" target="_blank">
          //       <img className='default' src="assets/images/social-03.svg" alt="" />
          //       <img className='active' src="assets/images/social-03-gallery.svg" alt="" />
          //     </a>
          //   </li>
          //   <li>
          //     <a href="https://www.linkedin.com/company/frestonanalytics" target="_blank" >
          //       <img className='default' src="assets/images/social-04.svg" alt="" />
          //       <img className='active' src="assets/images/social-04-gallery.svg" alt="" />
          //     </a>
          //   </li>
          // </ul>
//         </div>
//       </div>
//       <div className="row">
//         <div className="col-md-12">
//           <hr className="hr" />
//         </div>
//       </div>
//       <div className="row align-items-center">
//         <div className="col-md-6">
//           <p className="copyright">
//             © 2023 Freston Analytics. All Rights Reserved.
//           </p>
//         </div>
//         <div className="col-md-6">
//           <ul className="footer-small-nav">
//             <li>
//               <a href="">GDPR</a>
//             </li>
//             <li>
//               <a href="">Terms of Use</a>
//             </li>
//             <li>
//               <a href="">Cookie Policy</a>
//             </li>
//           </ul>
//         </div>
//       </div>
//     </div>
//   </footer>
// )


// .footer-top{
//   display: flex;
//   flex-direction: row;

//   .logo-container{
//       display: flex;
//       flex-direction: column;
//   }

//   .link-container{
//       display: flex;
//       flex-direction: row;
//       .link-head{
//           background-color: beige;
//       }
//       .link{
//           color: red;
//       }
//   }
// }

// .footer-bottom{
//   display: flex;
//   flex-direction: row;
// }

return(
  <footer className='footer-section' > 

  <div className='section-container'>
    <div className='inner-container'>
        <div className='footer-top'>

          <img
            src={whiteLogo}
            alt=""
            style={{width:"301px",height:"33px"}}
            className=""
          />
          {/* <h2 className="footer-title">
            We Make <br />
            Digital Transformation
          </h2> */}
          {/* <h2 className="footer-title">
          Freston  <br/> Analytics
          </h2> */}

            <div className='link-container'>
              <div>
          {
            footerLink.map((item,index)=>{
              return(
                <ul className="footer-list" key={index}>
                  
                  {
                    item.map((link,index)=>{
                      if(index===0){
                        return(
                          <h5 className="head" key={index}>{link.label}</h5>
                        )
                      }else{
                        return(
                          <li key={index}>
                            <Link to={`${link.link}`}>{link.label}</Link>
                          </li>
                          )
                      }
                    })
                  }

              </ul>
              )
            })
          }
              </div>
            </div>
        </div>
        <div className='footer-bottom'>

          <div className='socialmedia-container'>

          <ul className="footer-social" >
            <li>
              <a href="https://www.instagram.com/frestonanalytics" target="_blank" rel="noreferrer" >
                <img className='default' src={social_01} alt="" />
                <img className='active' src={social_gallery_01} alt="" />
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/frestonanalytics" target="_blank" rel="noreferrer" >
                <img className='default' src={social_04} alt="" />
                <img className='active' src={social_gallery_04} alt="" />
              </a>
            </li>
            <li>
              <a  href="https://www.facebook.com/frestonanalytics" target="_blank" rel="noreferrer" >
                <img className='default' src={social_03} alt="" />
                <img className='active' src={social_gallery_03} alt="" />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/frestonanalytic" target="_blank" rel="noreferrer" >
              <img className='default' src={social_02} alt="" />
                <img className='active' src={social_gallery_02} alt="" />
              </a>
            </li>
          </ul>
          
          </div>
          
          <div className='footer-bar'></div>

          <div className="copyright-container">
                <div >
                  <p className="copyright">
                    © {date.getFullYear()} Freston Analytics. All Rights Reserved.
                  </p>       
                </div>
                <div >
                <ul>
                    <li>
                      <a href="/gpdr">GDPR</a>
                  </li>
                  <li>
                      <a href="/terms-of-use">Terms of Use</a>
                    </li>
                  <li>
                      <a href="/cookie-policy">Cookie Policy</a>
                    </li>
                  </ul>
                </div>
          </div>

        </div>
    </div>
  </div>

  </footer>
)
}
 
export default Footer;