import {ButtonLink } from "../../components/ui/button";
import React from "react";

const BigDataInsight = () => {

  return (
    <section
    
    id="our-services"
    className="our-services"
    data-theme="dark"
    theme="dark"
    title="our services"
    links="our services"
    linkaddres="/our-services"



    style={{margin:'auto'}}>
      <video
        className="background-video"
        autoPlay={true}
        loop={true}
        playsInline=""
        muted={true}
        title=""
      >
        <source src="assets/images/bigdata-video.mp4" type="video/mp4" />
      </video>
      <div className="container">

        <div className="row">
          <div className="col-md-7">
            <h2 className="section-title" title="">
              Transform your business with our powerful <b>Big Data Insights</b>
            </h2>
            <div style={{height:'30px',width:'100%'}}></div>
            {/* <ButtonLink to="/home/big-data"/> */}
          </div>
        </div>

        <div className='large-display'>

            <div className="row justify-content-end">
              
            <div className="col-md-4">
                  <div className="bigdata-box">
                  <h5 className="case-study"> Case Study </h5>
                  <h5 className="section-head">Data and Analytics</h5>
                  <p className="text">
                  Learn how Freston Analytics helped a telecom provider personalise their customer offerings by leveraging data using an automated call data system with advanced visualisation capabilities.
                  </p>
                  <ButtonLink small to='/operator-connect' label="Explore" />
                </div>
            </div>

            <div className="col-md-4">
                  <div className="bigdata-box">

                  <h5 className="case-study"> Case Study </h5>
                  <h5 className="section-head"> Technology Modernization </h5>
                  <p className="text">
                  Discover how a multinational corporation achieved seamless global communication with ELK stack's Managed Voice Network, improving call quality and network performance.
                  </p>
                  <ButtonLink small to='/global-managed-service' label="Explore" />
                </div>
            </div>

          </div>

        </div>

        <div className='mobile-display'>

            <div className='mobile-scroll'>

                        <div className='scroll-card'>
                            <h5 className="section-head">CASE STUDY</h5>
                            <div className="insight">
                              <p className="section-text ">
                              Recognizing the need to harness valuable insights from vast call data, our client initiated a project to develop advanced data analysis and visualization capabilities, enhancing their capacity for meaningful data interpretation.
                              </p>
                              <ButtonLink small to='/operator-connect' labelcolor="white" label="Explore" />
                            </div>
                        </div>

                        <div className='scroll-card'>
                            <h5 className="section-head">CASE STUDY</h5>
                            <div className="insight">
                              <p className="section-text ">
                               Our client is a multinational corporation with a presence in several countries. The company recently expanded its operations and needed a robust Managed Voice Network to handle their communication needs efficiently.
                              </p>
                              <ButtonLink small to='/global-managed-service' labelcolor="white" label="Explore" />
                            </div>
                        </div>

            </div>

        </div>

      </div>
    </section>
  );
  
}

export default BigDataInsight;