import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { useLocation,useNavigate  } from 'react-router-dom'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Layout from "../../components/layout";
import Loading from '../../components/common/loading';
import Breadcrumb from '../../components/common/bread-crumb';
import breadCrumbsPlugin from '../../utils/breadCrumbsPlugin'

import ManinBanner from './main-banner';
import ApiGatewaySolutions from './api-gateway-solutions';
import BigDataInsight from './big-data-insights';
import AISolution from './ai-operation-solution';
import UnifiedCommunication from './unified-communication';
import FiveG3GPPSpecification from './5g-3gpp-specification'
import AboutFreston from './about-freston';
import OurProduct from './our-products';
import ProceedFreston from './proceed-freston'
import OurCollaboration from './our-collabration'
import LetConnectUS from './let-coonect-us'



gsap.registerPlugin(ScrollTrigger);


export const sections = [
  {
    component: <ManinBanner />,
    clasname: 'main-banner',
    title: <>Latest News</>,
    theme: 'dark',
    headerColor: '#37113E',
    links: `WHO WE ARE,Services,Products,Industries,LET'S CONNECT`,
    linkaddres:`/who-we-are,/our-services,/our-products,/our-industry,/contact-us`
  },

  // services , industries producsts
  {
    component: <ApiGatewaySolutions />,
    clasname: 'api-solution',
    title: <>API Gateway <br/> Service & Integration</>,
    theme: 'light',
    headerColor: 'white',
    links: 'OUR SERVICES',
    linkaddres:`/our-solutions`
  },
  {
    component: <BigDataInsight />,
    clasname: 'bigdata-solution',
    title: <>Big Data <br/> Insights</>,
    theme: 'dark',
    headerColor: '#0B0E39',
    links: 'OUR SERVICES',
    linkaddres:`/our-services`
  },
  // {
  //   component: <AISolution />,
  //   clasname: 'ai-solution',
  //   title: <>AI <br/>Operations & Solution</>,
  //   theme: 'light',
  //   headerColor: 'white',
  //   links: 'OUR SERVICES',
  //   linkaddres:`/our-services`
  // },
  // {
  //   component: <UnifiedCommunication />,
  //   clasname: 'unified-solution',
  //   title: <>Unified <br/> Communication</>,
  //   theme: 'dark',
  //   headerColor: '#ABB2B9',
  //   links: 'OUR SERVICES',
  //   linkaddres:`/our-services`
  // },
  {
    component: <AboutFreston />,
    clasname: 'about-freston',
    title: <> About Us </>,
    theme: 'light',
    headerColor: 'white',
    links: 'WHO WE ARE',
    linkaddres:`/who-we-are`
  },
  // {
  //   component: <FiveG3GPPSpecification />,
  //   clasname: 'fiveG-solution',
  //   title: <> 5G and  3GPP <br/> Specification </>,
  //   theme: 'dark',
  //   headerColor: '#0B0E39',
  //   links: 'OUR SERVICES',
  //   linkaddres:`/our-services`
  // },
  // {
  //   component: <ProceedFreston />,
  //   clasname: 'our-learning',
  //   title: <>Freston <br/> Proceed</>,
  //   theme: 'dark',
  //   headerColor: '#4E4B54',
  //   links: 'OUR LEARNING PLATFORM',
  //   linkaddres:`/freston-proceed`
  // },
  {
    component: <OurProduct />,
    clasname: 'our-product-home',
    title: <> Our <br/> Products </>,
    theme: 'light',
    headerColor: 'white',
    links: 'OUR PRODUCTS',
    linkaddres:`/our-products`
  },
  // {
  //   component: <OurCollaboration />,
  //   clasname: 'our-collaborations',
  //   title: <>Freston <br/> Proceed</>,
  //   theme: 'light',
  //   headerColor: 'white',
  //   links: 'OUR COLLABORATION',
  //   linkaddres:`#`
  // },
  {
    component: <LetConnectUS />,
    clasname: 'let-connect',
    title: "Contact Us",
    theme: 'dark',
    headerColor: '#655674',
    links: 'WHO WE ARE',
    linkaddres:`/who-we-are`
  },
]

const Home = () => {

  const articleRef = useRef();
  const location = useLocation();


  useLayoutEffect(() => {

        // switch(location.hash){
        //     case "#api-solution":
        //         var targetElm = document.getElementById('api-solution');
        //         break;
        //     case "#bigdata-solution":
        //         var targetElm = document.getElementById('bigdata-solution');
        //         break;
        //     case "#ai-solution":
        //         var targetElm = document.getElementById('ai-solution');
        //         break;
        //     case "#unified-solution":
        //         var targetElm = document.getElementById('unified-solution');
        //         break;
        //     case "#fiveG-solution":
        //         var targetElm = document.getElementById('fiveG-solution');
        //         break;
        //     case "#about-freston":
        //         var targetElm = document.getElementById('about-freston');
        //         break;
        //     case "#our-product":
        //         var targetElm = document.getElementById('our-product');
        //         break;
        //     case "#our-learning":
        //         var targetElm = document.getElementById('our-learning');
        //         break;
        //     case "#our-collaborations":
        //         var targetElm = document.getElementById('our-collaborations');
        //         break;
        //     default:
        //         var targetElm = document.getElementById('root'); 
        //         break;
        // }

        // targetElm.scrollIntoView()

    const ctx = gsap.context(() => {
      // Target the two specific elements we have asigned the animate class
      breadCrumbsPlugin(articleRef.current.offsetHeight)
    }, articleRef);// <- Scope!

    return () => ctx.revert();

  }, []);

  useEffect(()=>{

    let productList = document.querySelectorAll(".product-list li");

    for (let i = 0; i < productList.length; i++) {

      productList[i].addEventListener("click", function () {
        let current = document.querySelectorAll(".product-list li.active");
        current[0].className = current[0].className.replace("active", "");
        this.className += "active";
      });

    }

  },[]);

  return (
    <Layout page="home"  >
      <article  id="fullpage" ref={articleRef} data-bs-spy="scroll" data-bs-target="#sectionsList" data-bs-offset="0">
        <Breadcrumb >
          {
            sections.map((section,index) => {
              return (
                <section  key={index} id={section.clasname} className={section.clasname} background-color={section.headerColor} data-theme={section.theme} theme={section.theme} title={section.title} links={section.links} linkaddres={section.linkaddres} >
                  {section.component}
                </section>
              )
            })
          }
        </Breadcrumb >
      </article>
    </Layout>
  )

}

export default Home;